import * as React from 'react'
import { scroller } from "react-scroll"

const TableOfContent = () => {
  const scrollToPolicy = (element) => {
    scroller.scrollTo(element, {
      duration: 500,
      smooth: true,
      offset: -65
    })
  }

  return (
    <>
      <div className="privacy-policy-toc py-5 px-7 text-white text-lg">
        Table of Contents
      </div>
      <ul className="h-content bg-[#fff3f3] px-7 py-6 text-[#707070] flex flex-col">
        <button
          className={`cursor-pointer pb-6 text-left`}
          onClick={() => scrollToPolicy("definitions")}
        >
          Definitions
        </button>
        <button
          className={`cursor-pointer pb-6 text-left`}
          onClick={() => scrollToPolicy("policy-1")}
        >
          Policy 1 - Collecting Personal Information
        </button>
        <button
          className={`cursor-pointer pb-6 text-left`}
          onClick={() => scrollToPolicy("policy-2")}
        >
          Policy 2 - Consent
        </button>
        <button
          className={`cursor-pointer pb-6 text-left`}
          onClick={() => scrollToPolicy("policy-3")}
        >
          Policy 3 - Using and Disclosing Personal Information
        </button>
        <button
          className={`cursor-pointer pb-6 text-left`}
          onClick={() => scrollToPolicy("policy-4")}
        >
          Policy 4 - Retaining Personal Information
        </button>
        <button
          className={`cursor-pointer pb-6 text-left`}
          onClick={() => scrollToPolicy("policy-5")}
        >
          Policy 5 - Ensuring Accuracy of Personal Information
        </button>
        <button
          className={`cursor-pointer pb-6 text-left`}
          onClick={() => scrollToPolicy("policy-6")}
        >
          Policy 6 - Securing Personal Information
        </button>
        <button
          className={`cursor-pointer pb-6 text-left`}
          onClick={() => scrollToPolicy("policy-7")}
        >
          Policy 7 - Providing Clients Access to Personal Information
        </button>
        <button
          className={`cursor-pointer pb-6 text-left`}
          onClick={() => scrollToPolicy("health-and-safety")}
        >
          Health and Safety Concerns
        </button>
      </ul>
    </>
  )
}

export default TableOfContent;