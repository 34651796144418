import * as React from "react"
import ContactForm from "../components/Home/Contact/ContactForm"
import Layout from "../components/layout"
import Content from "../components/PrivacyPolicy/Content"
import TableOfContent from "../components/PrivacyPolicy/TableOfContent"
import headerImage from "../images/privacy-policy-header.png"

const PrivacyPolicy = () => {

  return (
    <div>
      <Layout showHeader>
        <section id="header" className="min-h-screen flex flex-col">
          <div className="container mx-auto pt-16 pb-10 px-8 lg:pt-0 lg:pb-18">
            <h1 className="uppercase lg:font-semibold text-dark-grey text-4xl md:text-7xl heading pb-4">
              Privacy Policy
            </h1>
          </div>
          <div className="bg-[#D2D2D2] w-full">
            <img src={headerImage} alt="Privacy Policy"/>
          </div>
          <div className="bg-white w-full">
            <div className="container mx-auto w-full px-8 py-8 lg:px-0 lg:pt-24 pb-32">
              <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start">
                <div className={`w-full lg:w-[437px] pb-8 flex flex-col`}>
                  <TableOfContent />
                </div>
                <div className="flex flex-1 flex-col lg:px-8 lg:pl-16">
                  <div className="flex flex-1 flex-col">
                    <Content />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactForm />
      </Layout>
    </div>
  )
}

export default PrivacyPolicy
